.highlight pre { background-color: #333; }
.highlight .hll { background-color: #333333 }
.highlight .c { color: #008800; font-style: italic; background-color: #0f140f } /* Comment */
.highlight .err { color: #ffffff } /* Error */
.highlight .dl { color: #d3d638 }
.highlight .g { color: #ffffff } /* Generic */
.highlight .k { color: #fb660a; font-weight: bold } /* Keyword */
.highlight .l { color: #ffffff } /* Literal */
.highlight .n { color: #ffffff } /* Name */
.highlight .o { color: #ffffff } /* Operator */
.highlight .x { color: #ffffff } /* Other */
.highlight .p { color: #ffffff } /* Punctuation */
.highlight .cm { color: #008800; font-style: italic; background-color: #0f140f } /* Comment.Multiline */
.highlight .cp { color: #ff0007; font-weight: bold; font-style: italic; background-color: #0f140f } /* Comment.Preproc */
.highlight .c1 { color: #008800; font-style: italic; background-color: #0f140f } /* Comment.Single */
.highlight .cs { color: #008800; font-style: italic; background-color: #0f140f } /* Comment.Special */
.highlight .gd { color: #ffffff } /* Generic.Deleted */
.highlight .ge { color: #ffffff } /* Generic.Emph */
.highlight .gr { color: #ffffff } /* Generic.Error */
.highlight .gh { color: #ffffff; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #ffffff } /* Generic.Inserted */
.highlight .go { color: #444444; background-color: #222222 } /* Generic.Output */
.highlight .gp { color: #ffffff } /* Generic.Prompt */
.highlight .gs { color: #ffffff } /* Generic.Strong */
.highlight .gu { color: #ffffff; font-weight: bold } /* Generic.Subheading */
.highlight .gt { color: #ffffff } /* Generic.Traceback */
.highlight .kc { color: #fb660a; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #fb660a; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #fb660a; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #fb660a } /* Keyword.Pseudo */
.highlight .kr { color: #fb660a; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #cdcaa9; font-weight: bold } /* Keyword.Type */
.highlight .ld { color: #ffffff } /* Literal.Date */
.highlight .m { color: #0086f7; font-weight: bold } /* Literal.Number */
.highlight .s { color: #0086d2 } /* Literal.String */
.highlight .na { color: #ff0086; font-weight: bold } /* Name.Attribute */
.highlight .nb { color: #ffffff } /* Name.Builtin */
.highlight .nc { color: #ffffff } /* Name.Class */
.highlight .no { color: #0086d2 } /* Name.Constant */
.highlight .nd { color: #ffffff } /* Name.Decorator */
.highlight .ni { color: #ffffff } /* Name.Entity */
.highlight .ne { color: #ffffff } /* Name.Exception */
.highlight .nf { color: #ff2626; font-weight: bold } /* Name.Function */
.highlight .nl { color: #ffffff } /* Name.Label */
.highlight .nn { color: #ffffff } /* Name.Namespace */
.highlight .nx { color: #ffffff } /* Name.Other */
.highlight .py { color: #ffffff } /* Name.Property */
.highlight .nt { color: #fb660a; font-weight: bold } /* Name.Tag */
.highlight .nv { color: #fb660a } /* Name.Variable */
.highlight .ow { color: #ffffff } /* Operator.Word */
.highlight .w { color: #888888 } /* Text.Whitespace */
.highlight .mf { color: #0086f7; font-weight: bold } /* Literal.Number.Float */
.highlight .mh { color: #0086f7; font-weight: bold } /* Literal.Number.Hex */
.highlight .mi { color: #0086f7; font-weight: bold } /* Literal.Number.Integer */
.highlight .mo { color: #0086f7; font-weight: bold } /* Literal.Number.Oct */
.highlight .sb { color: #0086d2 } /* Literal.String.Backtick */
.highlight .sc { color: #0086d2 } /* Literal.String.Char */
.highlight .sd { color: #0086d2 } /* Literal.String.Doc */
.highlight .s2 { color: #0086d2 } /* Literal.String.Double */
.highlight .se { color: #0086d2 } /* Literal.String.Escape */
.highlight .sh { color: #0086d2 } /* Literal.String.Heredoc */
.highlight .si { color: #0086d2 } /* Literal.String.Interpol */
.highlight .sx { color: #0086d2 } /* Literal.String.Other */
.highlight .sr { color: #0086d2 } /* Literal.String.Regex */
.highlight .s1 { color: #0086d2 } /* Literal.String.Single */
.highlight .ss { color: #0086d2 } /* Literal.String.Symbol */
.highlight .bp { color: #ffffff } /* Name.Builtin.Pseudo */
.highlight .vc { color: #fb660a } /* Name.Variable.Class */
.highlight .vg { color: #fb660a } /* Name.Variable.Global */
.highlight .vi { color: #fb660a } /* Name.Variable.Instance */
.highlight .il { color: #0086f7; font-weight: bold } /* Literal.Number.Integer.Long */

/** Custom highlight **/
.highlight .language-sh {
    color: #67ff8d;
}

span.line-numbers {
    margin-right: 3px;
}
